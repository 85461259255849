
import { defineComponent } from "vue";
import { Form, Field } from "vee-validate";
import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";
import ElCurrencyInput from "@/components/financial/CurrencyInput.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n/index";

export default defineComponent({
  name: "customer-financial-statements-networth",
  components: {
    // ElCurrencyInputNoSymbol,
    ElCurrencyInput,
    Form,
    Field,
  },
  setup() {
    const { t, te, n } = useI18n();

    const nFormat = (data) => {
      return n(Number(data), "currency", "en-MY");
    };

    return { nFormat };
  },
  data() {
    const statements = {
      assets: {
        label: "Assets",
        total: 0,
        interestRate: 0,
        monthlyRepayment: 0,
        child: {
          cashCashEquivalent: {
            label: "Cash / Cash Equivalent",
            total: 0,
            interestRate: 0,
            monthlyRepayment: 0,
            child: {
              savingAccount: {
                label: "Savings Account",
                total: 0,
                interestRate: 0,
                monthlyRepayment: 0,
              },
              currentAccount: {
                label: "Current Account",
                total: 0,
                interestRate: 0,
                monthlyRepayment: 0,
              },
              takafulCashValue: {
                label: "Takaful Hayat Cash Value / Life Insurances Cash Value",
                total: 0,
                interestRate: 0,
                monthlyRepayment: 0,
              },
              tabungHaji: {
                label: "Tabung Haji",
                total: 0,
                interestRate: 0,
                monthlyRepayment: 0,
              },
              fixedDeposit: {
                label: "Fixed Deposit",
                total: 0,
                interestRate: 0,
                monthlyRepayment: 0,
              },
            },
          },
          investmentAssets: {
            label: "Investment Assets",
            total: 0,
            interestRate: 0,
            monthlyRepayment: 0,
            child: {
              equityShares: {
                label: "Equity (Shares)",
                total: 0,
                interestRate: 0,
                monthlyRepayment: 0,
              },
              fixedIncome: {
                label: "Bond/Fixed Income",
                total: 0,
                interestRate: 0,
                monthlyRepayment: 0,
              },
              unitTrustAsb: {
                label: "Unit Trust/ Islamic Unit Trust",
                total: 0,
                interestRate: 0,
                monthlyRepayment: 0,
              },
              investmentLinkedPolicies: {
                label: "Investment Linked Policies",
                total: 0,
                interestRate: 0,
                monthlyRepayment: 0,
              },
              educationallCashValue: {
                label: "Educational Plan Cash Value",
                total: 0,
                interestRate: 0,
                monthlyRepayment: 0,
              },
              propertyEgApartmentBungalow: {
                label: "Property eg. Apartment, Bungalow",
                total: 0,
                interestRate: 0,
                monthlyRepayment: 0,
              },
              epf: {
                label: "EPF",
                total: 0,
                interestRate: 0,
                monthlyRepayment: 0,
              },
              otherRetirementTools: {
                label: "Other Retirement Tools",
                total: 0,
                interestRate: 0,
                monthlyRepayment: 0,
              },
            },
          },
          personalUsedAssets: {
            label: "Personal Used Assets",
            total: 0,
            interestRate: 0,
            monthlyRepayment: 0,
            child: {
              ownerOccupiedResidentialPropertytLand: {
                label: "Owner Occupied Residential Property",
                total: 0,
                interestRate: 0,
                monthlyRepayment: 0,
              },
              cars: {
                label: "Cars ",
                total: 0,
                interestRate: 0,
                monthlyRepayment: 0,
              },
              homeFurnishings: {
                label: "Home Furnishings",
                total: 0,
                interestRate: 0,
                monthlyRepayment: 0,
              },
              jewelryCollections: {
                label: "Jewelry/Collections",
                total: 0,
                interestRate: 0,
                monthlyRepayment: 0,
              },
              others: {
                label: "Others",
                total: 0,
                interestRate: 0,
                monthlyRepayment: 0,
              },
            },
          },
        },
      },
      lblts: {
        label: "Liabilities",
        total: 0,
        interestRate: 0,
        monthlyRepayment: 0,
        child: {
          currLblts: {
            label: "Current Liabilities",
            total: 0,
            interestRate: 0,
            monthlyRepayment: 0,
            child: {
              creditCards: {
                label: "Credit Cards",
                total: 0,
                interestRate: 0,
                monthlyRepayment: 0,
              },
              overdraft: {
                label: "Overdraft",
                total: 0,
                interestRate: 0,
                monthlyRepayment: 0,
              },
              incomeTaxZakatPayable: {
                label: "Income Tax/Zakat Payable",
                total: 0,
                interestRate: 0,
                monthlyRepayment: 0,
              },
              otherBankLoans: {
                label: "Other Bank Loans",
                total: 0,
                interestRate: 0,
                monthlyRepayment: 0,
              },
            },
          },
          longTermLblts: {
            label: "Long Term Liabilities",
            total: 0,
            interestRate: 0,
            monthlyRepayment: 0,
            child: {
              residentialHomeLoan: {
                label: "Residential Home Finance / Home Loan",
                total: 0,
                interestRate: 0,
                monthlyRepayment: 0,
              },
              carHirePurchase: {
                label: "Car Hire Purchase",
                total: 0,
                interestRate: 0,
                monthlyRepayment: 0,
              },
              investmentProperty: {
                label: "Investment Property",
                total: 0,
                interestRate: 0,
                monthlyRepayment: 0,
              },
              others: {
                label: "Others",
                total: 0,
                interestRate: 0,
                monthlyRepayment: 0,
              },
            },
          },
        },
      },
      networth: {
        label: "Networth",
        total: 0,
        interestRate: 0,
        monthlyRepayment: 0,
      },
    };

    let isLoading = true;
    const route = useRoute();
    const store = useStore();
    const cstmr_id = route.params.customer_id ?? null;
    const fin_id = route.params.fin_id ?? null;
    const customer = {
      birthdate: "",
      company_id: 0,
      detail: {},
      email: "",
      facilities: [],
      gender: "",
      media: [],
      name: "",
      nric: "",
      phone: "",
      package: "",
      net_worth_secured_liabilities: [],
      net_worth_secured_total: [],
      net_worth_unsecured_liabilities: [],
      net_worth_unsecured_total: [],
    };

    return {
      router: useRouter(),
      isLoading,
      statements,
      store,
      fin_id,
      cstmr_id,
      customer,
      finDetails: null,
      isFinReady: false,
    };
  },
  async mounted() {
    setCurrentPageBreadcrumbs("Networth Statement", [
      "Customer",
      "Financial Statements HH",
    ]);
    this.init();
  },
  methods: {
    init() {
      if (this.cstmr_id) {
        this.store.dispatch(Actions.GET_CUSTOMER, this.cstmr_id).then(() => {
          this.customer = this.store.getters.getCustomerData;
          this.isLoading = false;
        });
      }

      if (this.fin_id) {
        this.store
          .dispatch(Actions.GET_FINANCIAL_STATEMENT, this.fin_id)
          .then(() => {
            this.statements = this.store.getters.getFinancialStatementData.raw.statements;
          });
      }
    },

    calculateAssets(ind) {
      console.log(ind);
      this.statements.assets.child[ind].total = 0;
      this.statements.assets.total = 0;

      for (let ChildAsset of Object.keys(
        this.statements.assets.child[ind].child
      )) {
        this.statements.assets.child[ind].total += this.statements.assets.child[
          ind
        ].child[ChildAsset].total;
      }

      this.statements.assets.total =
        this.statements.assets.child.cashCashEquivalent.total +
        this.statements.assets.child.investmentAssets.total +
        this.statements.assets.child.personalUsedAssets.total;

      this.statements.networth.total =
        this.statements.assets.total - this.statements.lblts.total;
    },

    calculateLblts(ind) {
      console.log(ind);
      this.statements.lblts.child[ind].total = 0;
      this.statements.lblts.total = 0;
      this.statements.lblts.monthlyRepayment = 0;

      for (let ChildAsset of Object.keys(
        this.statements.lblts.child[ind].child
      )) {
        this.statements.lblts.child[ind].total = 0;
        this.statements.lblts.child[ind].monthlyRepayment = 0;
      }

      for (let ChildAsset of Object.keys(
        this.statements.lblts.child[ind].child
      )) {
        this.statements.lblts.child[ind].total += this.statements.lblts.child[
          ind
        ].child[ChildAsset].total;

        this.statements.lblts.child[
          ind
        ].monthlyRepayment += this.statements.lblts.child[ind].child[
          ChildAsset
        ].monthlyRepayment;
      }

      this.statements.lblts.total =
        this.statements.lblts.child.currLblts.total +
        this.statements.lblts.child.longTermLblts.total;

      this.statements.lblts.monthlyRepayment =
        this.statements.lblts.child.currLblts.monthlyRepayment +
        this.statements.lblts.child.longTermLblts.monthlyRepayment;

      this.statements.networth.total =
        this.statements.assets.total - this.statements.lblts.total;
    },
    onPrint() {
      window.print();
    },
    onSubmit(value) {
      const data = {
        id: this.fin_id,
        user_id: this.cstmr_id,
        financial_statement_type_id: 3,
        created_by: this.store.getters.currentUser.id,
        raw: {
          statements: this.statements,
        },
      };

      if (this.fin_id) {
        this.store
          .dispatch(Actions.UPDATE_FINANCIAL_STATEMENT, data)
          .then(() => {
            Swal.fire({
              title: "Congratulation!",
              text: "Cash Flow has been updated!",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "OK",
            }).then(() => {
              this.router.push({
                path: `/customers/details/${this.cstmr_id}/financial-statements/hh/list`,
              });
            });
          });
      } else {
        this.store
          .dispatch(Actions.STORE_FINANCIAL_STATEMENT, data)
          .then(() => {
            Swal.fire({
              title: "Congratulation!",
              text: "Cash Flow has been saved!",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "OK",
            }).then(() => {
              this.router.push({
                path: `/customers/details/${this.cstmr_id}/financial-statements/hh/list`,
              });
            });
          });
      }
    },
  },
});
